import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./Card.module.css";
export default function Card({ title, subtitle, text, link, icon, width }) {
  return (
    <div className={styles.card} style={{ width: `${width}` }}>
      <h2 className={styles.title}>{title}</h2>
      <h1 className={styles.subtitle}>{subtitle}</h1>
      <p className={styles.text}>{text}</p>
      <FontAwesomeIcon className={styles.icon} icon={icon} />
      <Link className={styles.link} to="/">
        View
      </Link>
    </div>
  );
}
