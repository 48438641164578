import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import styles from "./SideNav.module.css";
export default function SideNav({ visible, toggle }) {
  return (
    <div className={visible ? styles.sideNavOpen : styles.sideNavClosed}>
      <FontAwesomeIcon icon={faTimes} onClick={toggle} className={styles.exit} />
      <NavLink exact={true} className={styles.link} activeClassName={styles.active} to="/">
        Home
      </NavLink>
      <NavLink className={styles.link} activeClassName={styles.active} to="/projects">
        Projects
      </NavLink>
      <NavLink className={styles.link} to="/projects">
        LinkedIn
      </NavLink>
      <NavLink className={styles.link} to="/projects">
        GitHub
      </NavLink>
    </div>
  );
}
