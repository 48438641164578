import React from "react";
import Header from "../components/Header";
import Card from "../components/Card";
export default function Projects() {
  const projectData = [
    {
      title: "Weather Forecast",
      subtitle: "API component",
      text: "Sample API component for weather.",
    },
    {
      title: "Rusty Anchor Website",
      subtitle: "React Web App",
      text: "Website built for storage & rental business.",
      link: "www.rustyanchorsc.com",
    },
    {
      title: "USC Visitors Center",
      subtitle: "React Native App",
      text: "Geolocation Services Mobile App",
    },
    {
      title: "E-Learning App",
      subtitle: "React App",
      text: "Dynamic course building app.",
    },
    {
      title: "Server App",
      subtitle: "Server-Side App",
      text: "Website rendered as SSA",
    },
    {
      title: "Sample Component",
      subtitle: "React Components",
      text: "Custom react components.",
    },
  ];

  return (
    <div className="App">
      <Header />
      <div className="grid">
        {projectData.map((project) => {
          return <Card title={project.title} subtitle={project.subtitle} text={project.text} link={project.link} />;
        })}
      </div>
    </div>
  );
}
