import {
  faFacebook,
  faGithub,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faBars, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useRef, useState } from "react";
import styles from "./Home.module.css";
import SideNav from "../components/SideNav";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import logo from "../assets/logo.svg";
import uscApp from "../assets/uscApp.png";
import elearning from "../assets/elearning.png";
export default function Home() {
  const [visible, setVisible] = useState(false);
  const toggleMenu = () => {
    setVisible(!visible);
  };
  const section1 = useRef();
  const section2 = useRef();
  const section3 = useRef();
  const section4 = useRef();
  const logoRef = useRef();
  const card1Ref = useRef();
  const card2Ref = useRef();
  const aboutRef = useRef();
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.fromTo(
      section2.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: section1.current,
          scrub: true,
          start: "top",
          end: "bottom",
          pin: true,
        },
      }
    );
  }, []);

  const Card = (props) => {
    return (
      <div className={styles.cardContainer} ref={props.cardRef}>
        <img src={props.src} />
      </div>
    );
  };

  return (
    <Fragment>
      <div className={styles.page}>
        <SideNav visible={visible} toggle={toggleMenu} />
        <section className={styles.sectionOne} ref={section1}>
          <div className={styles.header}>
            <img src={logo} alt="logo" className={styles.logo} />
            <div>
              <FontAwesomeIcon
                className={styles.socialIcon}
                icon={faLinkedin}
              />
              <FontAwesomeIcon className={styles.socialIcon} icon={faGithub} />
              <FontAwesomeIcon
                className={styles.socialIcon}
                icon={faFacebook}
              />
              <FontAwesomeIcon
                className={styles.socialIcon}
                icon={faInstagram}
              />
            </div>
            <FontAwesomeIcon
              className={styles.menuIcon}
              icon={faBars}
              onClick={toggleMenu}
            />
          </div>
          <div className={styles.title}>
            <h1>
              <span style={{ opacity: "0.6" }}>LACHE</span> PORTER
            </h1>
            <h3>React Developer</h3>
            <p>Columbia, SC</p>
          </div>
          <div className={styles.content}>
            <div className={styles.profileImage} ref={logoRef}></div>
          </div>
          <div className={styles.footer}>
            <nav>
              <ul>
                <li>
                  <a href="/">About Me</a>
                </li>
                <hr />
                <li>
                  <a href="/">Projects</a>
                </li>
                <hr />
                <li>
                  <a href="/">Contact</a>
                </li>
                <hr />
                <li>
                  <a href="/">Social</a>
                </li>
              </ul>
              <hr />
            </nav>
            <FontAwesomeIcon icon={faCaretDown} className={styles.downIcon} />
          </div>
        </section>

        <section className={styles.sectionTwo} ref={section2}>
          <div ref={aboutRef}>
            <h3>Bio</h3>
            <ul id="bio">
              <li>A creative spirit making simple ideas, simply awesome.</li>
            </ul>
            <br />
            <h3>Education</h3>
            <ul id="contact">
              <li>lacheporter@gmail.com</li>
              <li>803-529-xxxx</li>
              <li>Columbia, SC</li>
            </ul>
            <br />
            <h3>Experience</h3>
            <ul id="Experience">
              <li>Javascript | 3 years</li>
              <li>HTML/CSS | 3 years</li>
              <li>React | 2 years</li>
            </ul>
            <br />
            <h3>Services</h3>
            <ul id="services">
              <li>Web Development</li>
              <li>Mobile Applications</li>
              <li>UI Design</li>
            </ul>
            <br />
            <h3>Education</h3>
            <ul id="education">
              <li>University of South Carolina</li>
              <li>Bachelors in Information Technology</li>
            </ul>
            <br />
            <h3>Projects</h3>
            <ul id="projects">
              <li>
                <h1>12</h1>
              </li>
            </ul>
          </div>
        </section>
        <section className={styles.sectionThree} ref={section3}>
          <a href="https://react-learning-template.netlify.app/">
            <Card cardRef={card1Ref} name="e-learning" src={elearning} />
          </a>
          <div className={styles.cardInfo}>
            <h1>React Project | E-Learning</h1>
            <p>
              Simple responsive learning template built with React. Comes with
              search, progress, dark mode, quizzes, and report card.
            </p>
            <br />
          </div>
        </section>
        <section className={styles.sectionFour} ref={section4}>
          <Card cardRef={card2Ref} name="e-learning" src={uscApp} />
          <div className={styles.cardInfo}>
            <h3>React Native</h3>
            <h1>USC Vistors APP</h1>

            <p>
              Mobile application built with React Native that allows users to
              explore the campus in an interactive way, while alos getting
              rewarded!
            </p>
            <br />
          </div>
        </section>
      </div>
    </Fragment>
  );
}
