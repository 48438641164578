import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import profile from "../assets/profileCircle.png";
import styles from "./Header.module.css";
// import SideNav from "./SideNav";
export default function Header({ toggleMenu, visible }) {
  return (
    <>
      <div className={styles.header}>
        <NavLink className={styles.profileContainer} to="/">
          {/* <img src={profile} alt="profile_picture" href="/" /> */}
        </NavLink>
        <nav className={styles.navigation}>
          <NavLink className={styles.navLink} exact={true} activeClassName={styles.active} to="/">
            Home
          </NavLink>
          <NavLink className={styles.navLink} activeClassName={styles.active} to="/projects">
            Projects
          </NavLink>
          <NavLink className={styles.navLink} to="/">
            LinkedIn
          </NavLink>
          <NavLink className={styles.navLink} to="/">
            GitHub
          </NavLink>
        </nav>
        <FontAwesomeIcon
          className={visible ? styles.menuClosed : styles.menuOpen}
          icon={visible ? faTimes : faBars}
          onClick={toggleMenu}
        />
        <div className={styles.skewColor1} />
        <div className={styles.skewColor2} />
      </div>
      {/* <SideNav visible={visible} /> */}
    </>
  );
}
